import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const ConcursoPage = () => (
  <Layout>
    <SEO title="Concurso" />
    <section className="intro">
      <div className="intro-container">
        <h2>Concurso</h2>
        <p>Você tem interesse em se candidatar a ser <strong>o próximo Mr. Rubber Brasil</strong>? Confira as informações abaixo para participar do concurso como candidato.</p>
      </div>
    </section>
    <section className="content">
      <div className="content-container">
        <p>Seja bem-vindo ao segundo concurso ao título: Mr. Rubber Brasil, que se dará no dia 24 de setembro de 2022 com o intuito de celebrar a comunidade nacional do látex/borracha com estilo próprio, instigante, pervertido e divertido.</p>
        <p>Apoiadores e participantes de diversas partes do mundo estarão presentes para apoiar e se divertirem durante um fim de semana dedicado ao fetiche da borracha. Nossa programação de fim de semana aguarda os participantes e concorrentes/competidores. Estamos ansiosos para recebê-lo nos locais do evento.</p>
        <ul>
          <li><strong>Datas</strong>: São Paulo, 24 de setembro de 2022</li>
          <li><strong>Localização</strong>: São Paulo, Brasil</li>
          <li><strong>Local</strong>: Clube Upgrade, R. Santa Isabel, 198 - Vila Buarque, São Paulo - SP, 01221-010</li>
        </ul>
        <h3>Regras de entrada</h3>
        <p>Cada candidato no concurso Mr. Rubber Brasil 2022:</p>
        <ul>
          <li>deve ser masculino e ter pelo menos dezoito anos de idade antes do começo do concurso;</li>
          <li>deve demonstrar um interesse em borracha/látex, BDSM e fetiches relacionados conforme a determinação do júri;</li>
          <li>deve falar português fluente ou nativo e ser residente no Brasil ou cidadão brasileiro;</li>
          <li>deve concordar em aparecer no palco vestido de borracha/látex apropriado e equipamentos de fetiche e deve participar em todas as rodadas julgadas do concurso, em conformidade com as leis locais de nudez e obscenidade. Para este concurso, não é permitido nudez;</li>
          <li>deve possuir todos os equipamentos usados no palco durante o concurso, com a exceção de adereços utilizados durante o segmento "Fantasia Emborrachada";</li>
          <li>deve concordar em representar o título Mr. Rubber Brasil 2022, se ganhar, pelo ano de título inteiro e deve não concorrer em nenhum outro concurso de couro/borracha/fetiche até o final do ano de obtenção do título de Mr. Rubber Brasil;</li>
          <li>deve concordar em permitir fotos a serem tiradas e colocadas nos perfis sociais do Mr. Rubber Brasil, nas publicações relevantes e em atividades promocionais.</li>
          <li>deve concordar em estar em consonância e diálogo com a organização do evento, antes, durante e posteriormente ao concurso, ao qual seguirá as tratativas, orientações e deliberações desta organização, até o final do ano respectivo a eleição, em que pesa suas atribuições no tópico de organização e suas atribuições.</li>
        </ul>
        <p>Não há nenhum requisito preliminar para concorrer no concurso Mr. Rubber Brasil 2022 e candidatos podem ser autopatrocinados. Porém, recomendamos que os candidatos consigam patrocínio por clubes relevantes, negócios ou indivíduos para contarem com ajuda a concorrer pelo título.</p>
        <p>Os candidatos devem chegar em São Paulo, no local do concurso e deve aparecer pessoalmente em todos os eventos marcados durante o final de semana do Mr. Rubber Brasil 2022, até data previamente informada.</p>
        <h3>Prazos para candidatura</h3>
        <p>O formulário de inscrição fechará no dia 31 de agosto (quarta-feira) À MEIA-NOITE, horário São Paulo. Envie uma foto de você vestido de látex ao contato mrrubberbr@gmail.com. Os candidatos devem trazer uma carteira de identidade ou passaporte válido.</p>
        <p>Os candidatos devem estar presentes para o começo do concurso às 15:00 (duas da tarde) no dia 24 de Setembro (sábado) no Clube Upgrade, R. Santa Isabel, 198 - Vila Buarque, São Paulo - SP, 01221-010.</p>
        <h3>Orientações e regras à pessoa eleita</h3>
        <ul>
          <li>Orienta-se que o vencedor do título compareça no Mr. International Rubber, a ser realizada em Chicago cerca de um ano após a sua vitória.</li>
          <li>Como o vencedor do título, sua principal responsabilidade são:
            <ol>
              <li>promover o fetiche de borracha/látex nacional.</li>
              <li>representar em eventos específicos o fetiche de borracha/látex nacional e</li>
              <li>promover e reconhecer ações da comunidade que estimulem a criação de um ambiente fetichista de latéx/borracha com estilo próprio, respeitando a regionalidade e nacionalidade da comunidade.</li>
            </ol>
          </li>
          <li>É fortemente encorajado a participar de eventos de fetiche, para  promover o cenário do latéx/borracha do Brasil, bem como promover e reconhecer as empresas que patrocinaram a edição do evento de obtenção do seu título. Ele deve usar seu melhor julgamento sobre quais eventos irão beneficiá-lo e o título, juntamente com a organização.</li>
          <li>Orienta-se, caso busque concorrer ao MIR, que o eleito deve manter contato regular e freqüente com a equipe do evento nacional, assim como com a organização da International Rubber, de forma apoiar no planejamento e promoção e fornecer atualizações regulares em www.MIRubber.com.</li>
        </ul>
        <h3>Pacote de prêmios</h3>
        <p>O pacote de prêmios para os vencedores é diferente a cada ano e depende de doações de fornecedores e apoiadores do Mr. Rubber Brasil.</p>
        <p>O pacote de prêmios preliminar será listado nas semanas anteriores ao concurso, mas não será finalizado até pouco antes do concurso.</p>
        <h3>Organização do Mr. Rubber Brasil: Objectivo, regras e premissas</h3>
        <p>O Mr. Rubber Brasil possui uma organização, que permanece, salvo a solicitação de desligamento por parte de seus pares. Esta organização é vedada a participar do concurso, devido ao respeito à imparcialidade de ética.</p>
        <p>A administração desta organização, visa zelar pelo respeito e defesa, transparência, ética, primor a diversidade e respeito humano, pilares da prática do fetichismo sadio, consensual e seguro.</p>
        <p>Cabe a organização a advertir e em caso de necessidade, retirar o título eleito ao ganhador, em caso de:</p>
        <ul>
          <li>Em caso de agressão e promoção de discurso de ódio, demais ações que firam o propósito de celebração do fetiche do látex/borracha, que possui o concurso.</li>
          <li>Em caso de  consecutiva ausência na realização de atividades de promoção do fetichismo, após convite da organização da presença em eventos necessários para promoção e celebração do fetiche do látex/borracha.</li>
          <li>Demais ações que em análise, atentem contra os preceitos do concurso e contra a comunidade do látex/borracha brasileira, que impeçam a representação do mesmo, desta comunidade, em comum acordo entre a organização do evento.</li>
        </ul>
        <p>A deliberação de retirada de título se dará, seguindo as etapas:</p>
        <ol>
          <li>Análise e ponderação dos atos realizados que infringem o objetivo do concurso e seu ideário de representação, seja por origem de denúncia e/ou constatação de integrantes da organização.</li>
          <li>Deliberação dos pares da organização, por meio de votação, em validação de necessidade de realização de retirada imediata de título e representação (em decorrência de ato grave de impacto extremamente deletério as premissas do concurso) e/ou advertência, que se dará da seguinte forma:
            <ol>
              <li>Realização de notificação inicial, escrita, descrevendo a orientação para busca de reparação e adequação.</li>
              <li>Realização de segunda notificação, escrita, descrevendo a orientação para busca de reparação, adequação e apresentação de que a terceira advertência, implicará na perda do título</li>
              <li>Apresentação de última notificação, informando a perda de título.</li>
            </ol>
          </li>
        </ol>
        <h3>Cronograma base</h3>
        <p>As agendas iniciais e finais do concurso serão fornecidas com prévia antecipação e estão sujeitas a alterações.</p>
        <h4>Encontro de Orientação ao Concorrente</h4>
        <p>Os concorrentes se reúnem com a organização para revisar o cronograma de fim de semana, as regras do concurso e as expectativas dos vencedores. Os juízes também poderão estar presentes para socializar.</p>
        <h4>Mr. Rubber (Entrevistados do Concorrente)</h4>
        <p>Todos os competidores devem se apresentar para o processo de entrevista.</p>
        <h4>Mr. Rubber Concurso (Apresentação Inicial)</h4>
        <p>Juízes e Concorrentes são apresentados ao público. Os competidores são juízes nas categorias da competição - “Imagem de borracha”.</p>
        <h4>Mr. Rubber Concurso (Análise do Júri)</h4>
        <p>Os participantes serão analisados em suas performances, entrevistas e história pregressa na comunidade de látex/borracha brasileira, em que de acordo com análise da organização, caberá se a  participação do público no concurso é viável.</p>
        <h4>Mr. Rubber Concurso - Anúncio do Mr. Rubber Eleito</h4>
        <p>O participante eleito, será anunciado, conforme deliberação dos jurados(as) e do público (caso houver).</p>
        <h3>Custos envolvidos</h3>
        <p>Não há custo envolvido na inscrição.</p>
        <p>Não há premiação atrelada a valor monetário ou de qualquer ordem, emitida pela organização (salvo patrocínio obtido por esta, com a finalidade incluir em possível prêmio anunciado no período do evento, caso houver).</p>
        <p>Não há responsabilidade da organização, em decorrência de gastos ocorridos para estadia, entrada e alimentação de envolvidos e participantes do concurso.</p>
        <h3>Confraternização com jurados</h3>
        <p>Concorrentes do Mr. International Rubber são encorajados a conhecer os juízes e interagir com eles. No entanto, os concorrentes devem ser proibidos de ter reuniões privadas com os juízes antes do anúncio dos resultados.</p>
        <p>Para evitar violações acidentais desta política e possível remoção do concurso, todos os concorrentes e os juízes devem exibir seus crachás de identificação em todos os momentos.</p>
        <h3>Participação de fim de semana</h3>
        <p>Os participantes são fortemente encorajados a participar de todos os eventos do fim de semana. O concurso Mr. Rubber Brasil tem como objetivo viabilizar um bom tempo e promover o fetiche de borracha. Os participantes são julgados durante todo o fim de semana por juízes e participantes que refletem em suas pontuações finais do concurso.</p>
        <p><Link to="/" className="call-to-action">Voltar à página inicial</Link></p>
      </div>
    </section>
  </Layout>
)

export default ConcursoPage
